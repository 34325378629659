<template>
  <div class="bg-fff billing pr-12 pl-12 pt-12 autobox" v-show="exposeState.isshow">
    <div class="amount mb-14">开票金额：<span>¥{{ state.amount }}</span></div>
    <el-table :data="state.selectNotice" class="mb-12 brtablebox"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color}">
      <el-table-column prop="number" width="200" label="订单编号" />
      <el-table-column :align="'center'" prop="rechargeTypeCn" label="订单类型" />
			<el-table-column :align="'center'" prop="packageName" label="产品名称" />
      <el-table-column :align="'center'" label="实付金额">
        <template #default="scope">
          <div class="dp-fc">
            ¥{{ scope.row.rechargeAmountCn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="paymentDate" label="支付时间" />
    </el-table>
    <div class="dp-f jc-sb ai-c mb-15">
      <div class="headertitle">发票抬头：</div>
      <!-- <oabutton v-if="state.typeis!=1" title="修改" CSStype=10 width="52" height="28" @buttonclick="updateInvoice"
        style="background: rgba(24,144,255,0.1);"/> -->
    </div>
    <div class="baseInfobox dp-f fw-w mb-28">
      <div class="labelbox" v-for="(el, ind) in state.showBaseInfo" :key="ind">
        <div class="title">{{ el.name }}:</div>
        <span v-if="el.key == 'invoiceType'">{{ getinvoiceType(state.baseInfo.invoiceType) }}</span>
        <!-- <span v-else-if="el.key == 'invoiceParagraph' && el.isnull == false" style="color: #FF0000;">首次申请发票，请填写税号信息</span> -->
        <span v-else>{{ state.baseInfo[el.key] }}</span>
      </div>
    </div>
    <!-- <div class="dp-f jc-sb ai-c mb-15">
      <div class="headertitle">寄送地址：</div>
      <oabutton v-if="state.typeis!=1" :title="state.addressNotice.length < 1 ? '新增' : '修改'" CSStype=10 width="52" height="28"
        @buttonclick="addressClick" style="background: rgba(24,144,255,0.1);"/>
    </div>
    <el-table :data="state.addressNotice" class="mb-40"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }">
      <el-table-column :align="'center'" prop="recipientName" label="收件人姓名" />
      <el-table-column :align="'center'" prop="phone" label="电话号码" />
      <el-table-column :align="'center'" prop="address" label="地址" />
      <el-table-column :align="'center'" prop="postcodes" label="邮编" />
    </el-table> -->
    <div class="dp-f">
      <oabutton title="取消" CSStype=3 width="100" height="42" @buttonclick="exposeState.isshow = false" class="mr-18"/>
      <!-- <oabutton title="修改" CSStype=2 width="100" height="42"/> -->
      <oabutton title="提交" CSStype=2 width="100" height="42" @buttonclick="saveData" v-model:loading="loading" v-if="state.typeis!=1"/>
    </div>
    <!-- <operation ref="operationRef" @Emit="InvoiceEmit"/> -->
    <!-- <addressDialog ref="addressRef" @Emit="addressEmit"/> -->
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineExpose,computed,nextTick, defineProps } from 'vue'
import { getinvoiceType } from "@/utils/server/getcode.js"
import {handleMessage} from "@/utils/server/confirm.js"
import { httpToken } from "@/utils/request";
// import {getrechargeType} from "@/utils/server/getcode"
// import { convert } from "@/utils/util"
// import qs from "qs";
// import operation from "./billing/operation.vue"//操作弹框
// import addressDialog from "./billing/address.vue"//地址弹框
const loading = ref(false);// loading 显示
const operationRef = ref()//操作弹框ref
const addressRef= ref()//修改地址弹框
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const props = defineProps({
  invoiceAccount: {
    type: Object,
    required: true,
  }
})
const state = reactive({
  typeis:0,//0 默认 1查看
  amount: '0.00',//当前总金额
  selectNotice: [],//选中的表格数据
  baseInfo: computed(() => {
    return props.invoiceAccount
  }),
  showBaseInfo: [],//展示的基础数据
  addressNotice: []//地址信息展示表格数据
})
const emit = defineEmits(['Emit'])
const exposeState=reactive({
  isshow: false,
})
// 保存数据
const saveData=()=>{
  loading.value=true
  httpToken({
    method: "post",
    url: '/admin/account/addOrUpdateInvoiceAccount',
    data: {
      rechargeRecord:state.selectNotice.map(item=>{return item.id}),
      invoiceAccount:state.baseInfo,
      // invoiceAddress:state.addressNotice[0]
    }
  }).then(() => {
    handleMessage('发票申请成功，请耐心等待', 'success')
    emit('Emit')
    exposeState.isshow=false
    loading.value=false
  }).catch(()=>{
    loading.value=false
  })
}
// // 编辑寄送地址
// const addressEmit=(data)=>{
//   // console.log('编辑寄送地址',data)
//   state.addressNotice[0]={...data}
// }
// 编辑或新增寄送地址
// const addressClick=()=>{
//   if(state.addressNotice.length < 1){
//     unref(addressRef).addclick()
//   }else{
//     unref(addressRef).editclick(state.addressNotice[0])
//   }
// }
// 修改发票抬头
// const updateInvoice = (() => {
//   unref(operationRef).editclick(state.baseInfo)
// })
// 开发票功能
const setBilling = ((amount, data) => {
  // console.log('开发票功能',data)
  state.amount = amount
  state.selectNotice = data
  // state.baseInfo.companyname=userInfo.value.sysCompany.companyName
  console.log("baseinfo: ", state.baseInfo)
  console.log("baseinfo: ", props.invoiceAccount)
  resetshowBaseInfo()
  exposeState.isshow = true
})
// 重置发票抬头信息
const resetshowBaseInfo = (() => {
  state.showBaseInfo = [
    { name: '名称', key: 'invoiceHeader' },
    { name: '税号', key: 'invoiceParagraph'},
    { name: '发票类型', key: 'invoiceType' },
    { name: '开户银行', key: 'bank' },
  ]
  // state.addressNotice=[]
})
// resetshowBaseInfo()
// // 重新定义发票抬头
// const InvoiceEmit = ((ruleForm) => {
//   state.baseInfo = ruleForm
//   state.baseInfo.companyname=userInfo.value.sysCompany.companyName
//   resetshowBaseInfo()
//   setshowBaseInfo(ruleForm.invoiceType)
// })
// const setshowBaseInfo=(invoiceType)=>{
//   state.showBaseInfo[3].isnull = true
//   state.showBaseInfo.push({ name: '发票抬头', key: 'invoiceHeader' })
//   // 专用发票添加更多字段
//   if (invoiceType == 1) {
//     state.showBaseInfo.push({ name: '开户银行', key: 'bank' })
//     state.showBaseInfo.push({ name: '开户账号', key: 'bankNumber' })
//     state.showBaseInfo.push({ name: '注册地址', key: 'address' })
//     state.showBaseInfo.push({ name: '固定电话', key: 'fixedTelephone' })
//   }
// }
// 查看发票信息
// const showview=(data)=>{
//   console.log('查看发票信息',data)
//   state.selectNotice=[]
//   resetshowBaseInfo()
//   state.amount = data.invoiceAmount
//   console.log('state.selectNotice',state.selectNotice)
//   // 获取发票信息和邮寄地址
//   httpToken({
//     method: "post",
//     url: '/admin/account/getInvoiceAccount',
//     data: qs.stringify({
//       id:data.id
//     })
//   }).then((res) => {
//     console.log('获取发票信息和邮寄地址',res)
//     state.typeis=1
//     state.selectNotice[0] = {
//       number:res.data.rechargeRecordObj.number,//订单编号
//       rechargeTypeCn:getrechargeType(res.data.rechargeRecordObj.rechargeType),//订单类型
//       rechargeAmountCn:convert(res.data.rechargeRecordObj.rechargeAmount,false),//实付金额
//       paymentDate:res.data.rechargeRecordObj.paymentDate,//支付时间
//     }
//     state.baseInfo = res.data.invoiceAccount
//     state.baseInfo.companyname=userInfo.value.sysCompany.companyName
//     setshowBaseInfo(res.data.invoiceAccount.invoiceType)
//     state.addressNotice[0]=res.data.invoiceAddress
//     exposeState.isshow = true
//   })
// }
defineExpose({
  setBilling, exposeState
});
</script>
<style lang="scss" scoped >
.brtablebox {
  ::v-deep .el-table__inner-wrapper {
    border: 1px #e5e5e5 solid;
  }

  ::v-deep .el-scrollbar__bar {
    display: none;
  }
}

.billing {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  .baseInfobox {
    padding: 0 26px;
    background: #F7F7F7;
    padding-top: 20px;

    .labelbox {
      margin-bottom: 20px;
      width: 50%;
      font-size: 14px;
      color: #666666;
      display: flex;

      .title {
        width: 100px;
      }

      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }

  .amount {
    span {
      font-size: 20px;
      font-weight: 600;
      color: #1890FF;
    }
  }

  .headertitle {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
}
</style>