<template>
  <div class="w100 bg-fff pt-12 autobox" v-if="!state.isshow">
    <div class="w100 dp-f fw-w mb-24">
      <div class="input_box w-200">
        <div class="input_title">发票序号：</div>
        <el-input class="input" v-model="state.searchKey.invoiceNumber" :maxlength="config.inputMaxlength" show-word-limit />
      </div>
      <div class="input_box w-450">
        <div class="input_title">选择日期：</div>
        <el-date-picker class="input" v-model="state.searchKey.date" type="datetimerange" range-separator="→"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss" />
      </div>
      <oabutton title="搜索" CSStype=2 width="80" height="40"
				class="searcMR but" @buttonclick="getdataList"></oabutton>
    </div>
    <!-- <el-table :data="state.Notice" class="mb-20"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
      row-key="id"> -->
    <el-table :data="state.Notice" class="mb-20"
      :header-cell-style="{ color: config.table.color}"
      row-key="id">
      <el-table-column label="" width="60" />
      <el-table-column prop="invoiceNumber" label="发票序号" />
      <el-table-column prop="invoiceHeader" label="发票抬头" />
      <el-table-column prop="invoiceTypeName" label="发票类型" />
      <el-table-column :align="'center'" prop="invoiceAmount" label="发票金额" width="160" />
      <el-table-column prop="applicationDate" label="申请日期" />
      <el-table-column :align="'center'" label="收取方式" width="160" >
        电子发票
      </el-table-column>
      <el-table-column :align="'center'" prop="statusName" label="状态" width="160" />
      <el-table-column :align="'center'" label="操作">
        <template #default="scope">
          <div v-if="scope.row.status === 1">
            <el-link type="success" :href="scope.row.url">下载</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
      layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
  <billing ref="billingRef"></billing>
</template>
<script setup>
import { reactive, ref, unref,computed,onMounted } from 'vue'
// import { queryResourceTable } from "@/api/cancelToken.js"
import { httpToken } from "@/utils/request";
import {getinvoicing, getinvoiceType} from "@/utils/server/getcode.js"
import qs from "qs";
import billing from "../billing.vue"//开票页面
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const billingRef = ref();//开票页面ref
const state = reactive({
  isshow:false,
  searchKey: {
    invoiceNumber: '',//发票序号
    date: null,//日期
  },//搜索绑定值
  Notice: [],//表格数据
})
onMounted(()=>{
	state.isshow=computed(()=>{
		return unref(billingRef).exposeState.isshow
	})
})
// 查看点击
const showview=(row)=>{
  console.log('查看点击',row)
  unref(billingRef).showview(row)
}
// 列表调取 页数
const getdataList = ((val=1) => {
  state.multipleSelection = []
  currentPage.value = val;
  loading.value = true
  httpToken({
      url: '/admin/account/selectInvoice',
      data: qs.stringify({
        page: val,
        invoiceNumber: state.searchKey.invoiceNumber,
        date: JSON.stringify(state.searchKey.date),
      }),
      method: 'post'
  } ).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdataList(res.data.current)
      return
    }
    totalPage.value = res.data.total;
    res.data.records.forEach(item => {
      item.statusName=getinvoicing(item.status)
      item.invoiceTypeName = getinvoiceType(item.invoiceType)
    });
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
})
getdataList()
</script>
<style lang="scss" scoped ></style>